import { LoanProducts } from 'utils/constants'

export const valuesOfCatDetails = {
  autoEquity: {
    catWithoutIva: 59.24,
    tasaWithoutIva: 40.4,
    dateOfCalculate: 'Junio 2024',
    comission: '3.5',
    comissionForDelay: '300.00',
    comissionPorcentage: '20',
  },
  autoFin: {
    catWithoutIva: 49.96,
    tasaWithoutIva: 31.92,
    dateOfCalculate: 'Junio 2024',
    comission: '3.5',
    comissionForDelay: '300.00',
    comissionPorcentage: '20',
  },
  payroll: {
    catWithoutIva: 52.73,
    tasaWithoutIva: 40.77,
    dateOfCalculate: 'Diciembre 2023',
  },
  payrollAdvance: {
    catWithoutIva: 52.73,
    tasaWithoutIva: 40.77,
    dateOfCalculate: 'Diciembre 2023',
  },
  homeEquity: {
    catWithoutIva: 33.9,
    tasaWithoutIva: 23.79,
    dateOfCalculate: 'Diciembre 2023',
    comission: '2',
    moneyComission: '200.00',
  },
  generic: {
    catWithoutIva: 58.43,
    tasaWithoutIva: 39.45,
    comission: '3.5',
    dateOfCalculate: 'Diciembre 2023',
  },
}

export const getTranslationKey = productName => {
  switch (productName) {
    case LoanProducts.AUTO_EQUITY:
      return 'auto:cat'
    case LoanProducts.AUTO_FIN:
      return 'autoFin:cat'
    case LoanProducts.PAYROLL:
      return 'payroll:cat'
    case LoanProducts.PAYROLL_ADVANCE:
      return 'salary:cat'
    case LoanProducts.HOME_EQUITY:
      return 'home:cat'
    case LoanProducts.GENERIC:
      return 'generic:cat'
    default:
      return null
  }
}
