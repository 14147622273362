import { useFlags } from 'flagsmith/react'
import { LoanProducts } from 'utils/constants'
export const useFlagSmithValue = flagName => {
  const { [flagName]: featureFlag } = useFlags([flagName])
  return featureFlag.enabled ? featureFlag.value : ''
}

export const getFeatureFlagOfCat = productName => {
  switch (productName) {
    case LoanProducts.AUTO_EQUITY:
    case LoanProducts.AUTO_FIN:
      return 'dynamic_auto_cat_values'
    case LoanProducts.PAYROLL:
    case LoanProducts.PAYROLL_ADVANCE:
      return 'dynamic_payroll_cat_values'
    case LoanProducts.HOME_EQUITY:
    case LoanProducts.GENERIC:
      return 'dynamic_home_generic_cat_values'
    default:
      return null
  }
}
