import { styled, media, selectTheme } from '@creditas-ui/react'

const Container = styled.div`
  background: ${selectTheme('colors.neutral.0')};
  width: 100%;
`

const CatContainer = styled.div`
  max-width: 1366px;
  padding: 32px 20px 48px;

  ${media.up('5xl')} {
    margin: auto;
    padding-left: 0;
  }

  ${media.between('5xl', '8xl')} {
    margin: 0 71px;
  }

  a {
    text-decoration: none;
    color: ${selectTheme('colors.primary.90')};
    font-weight: 700;
  }

  strong {
    font-weight: 700;
  }
`

export { Container, CatContainer }
