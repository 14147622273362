import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import { Typography } from '@creditas-ui/react'
import { getFeatureFlagOfCat, useFlagSmithValue } from 'utils/useFutureFlags'
import { useTranslation } from 'react-i18next'
import { getTranslationKey, valuesOfCatDetails } from 'utils/getCatText'
import { CatContainer, Container } from './Cat.styles'

const propTypes = {
  productName: string,
}

const Cat = ({ productName }) => {
  const { t } = useTranslation()

  const [catValuesFromFlagsmith, setCatValuesFromFlagsmith] = useState(null)

  const featureFlagByProductName = getFeatureFlagOfCat(productName)
  const catValuesOfFlag = useFlagSmithValue(featureFlagByProductName ?? '')

  useEffect(() => {
    if (catValuesOfFlag) {
      setCatValuesFromFlagsmith(JSON.parse(catValuesOfFlag))
    }
  }, [catValuesOfFlag])

  const translationKey = getTranslationKey(productName)
  const defaultValues = valuesOfCatDetails[productName]

  const generateTranslationLines = () => {
    if (!translationKey) return null

    const mergedValues = { ...defaultValues, ...catValuesFromFlagsmith }

    const dynamicParams = {}
    for (const [key, value] of Object.entries(mergedValues)) {
      dynamicParams[key] = value === null ? null : value.toString()
    }

    return t(translationKey, dynamicParams)
  }

  const translationLines = generateTranslationLines()

  return (
    <Container>
      <CatContainer>
        <Typography
          component="p"
          variant="bodySmMedium"
          color="neutral.60"
          dangerouslySetInnerHTML={{
            __html: translationLines,
          }}
        />
      </CatContainer>
    </Container>
  )
}

Cat.propTypes = propTypes

export default Cat
